import React, {Component} from 'react';
import '../../styles/03-components/section.scss';
import '../../styles/02-common/input.scss';
import {initializeApp} from 'firebase/app';
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import {Link, useLocation} from 'react-router-dom';

const firebaseConfig = {
  apiKey: 'AIzaSyAueuZ7p5pKtr3ofVF848tmbdmiEW1OxVA',
  authDomain: 'offtogether-9c7fa.firebaseapp.com',
  databaseURL:
    'https://offtogether-9c7fa-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'offtogether-9c7fa',
  storageBucket: 'offtogether-9c7fa.appspot.com',
  messagingSenderId: '369641343917',
  appId: '1:369641343917:web:3ea8c840520fad48622f24',
  measurementId: 'G-7ZWME5SPLQ',
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

class WaitingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      message: '',
      acceptedPrivacyPolicy: false,
      plan: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // Den Plan aus der URL extrahieren
    const {search} = this.props.location;
    const urlParams = new URLSearchParams(search);
    const plan = urlParams.get('plan');
    this.setState({plan}); // Plan in den Zustand setzen
  }

  handleChange = event => {
    this.setState({email: event.target.value});
  };

  handleCheckboxChange = event => {
    this.setState({acceptedPrivacyPolicy: event.target.checked});
  };

  handleSubmit = async event => {
    event.preventDefault();
    const {email, acceptedPrivacyPolicy, plan} = this.state;

    if (!acceptedPrivacyPolicy) {
      this.setState({message: 'Bitte stimme den Datenschutzrichtlinien zu!'});
      return;
    }

    if (!email.includes('@')) {
      this.setState({message: 'Bitte eine gültige E-Mail-Adresse eingeben!'});
      return;
    }

    try {
      // Überprüfen, ob die E-Mail bereits in der Warteliste existiert
      const emailQuerySnapshot = await getDocs(
        query(collection(db, 'waitinglist'), where('email', '==', email)),
      );

      if (!emailQuerySnapshot.empty) {
        // Wenn die E-Mail schon vorhanden ist
        this.setState({
          message: 'Du stehst bereits auf der Warteliste.',
        });
        return;
      }

      // E-Mail-Adresse speichern, wenn sie nicht existiert
      await addDoc(collection(db, 'waitinglist'), {
        email,
        acceptedPrivacyPolicy,
        plan,
        acceptedPrivacyPolicyTimestamp: new Date(),
      });

      this.setState({
        message: 'Danke! Deine E-Mail wurde gespeichert.',
        email: '',
        acceptedPrivacyPolicy: false,
      });
    } catch (error) {
      console.error('Fehler beim Speichern: ', error);
      this.setState({
        message: 'Fehler beim Speichern. Bitte versuche es erneut.',
      });
    }
  };

  render() {
    return (
      <div className="ot-section">
        <div className="ot-section__container">
          <div className="ot-section__headline">
            <h1>Warteliste für OffTogether</h1>
          </div>
          <div className="ot-section__description">
            <p>Trage dich ein & gehöre zu den Ersten, die starten!</p>
          </div>
          <div>
            <form onSubmit={this.handleSubmit} className="form">
              <input
                type="email"
                placeholder="Deine E-Mail-Adresse"
                value={this.state.email}
                onChange={this.handleChange}
                required
                className="ot-input"
              />
              <div>
                <label className="ot-checkbox-label">
                  <input
                    type="checkbox"
                    className="ot-checkbox" // Vergrößert die Checkbox
                    checked={this.state.acceptedPrivacyPolicy}
                    onChange={this.handleCheckboxChange}
                  />
                  Ich stimme den&nbsp;
                  <Link to="/data-protection">Datenschutzrichtlinien</Link>
                  &nbsp;zu.
                </label>
              </div>

              <button type="submit" className="ot-primary-btn">
                <span className="ot-primary-btn__text">Jetzt Eintragen</span>
              </button>
            </form>
          </div>

          {this.state.message && (
            <p className="ot-section__message">{this.state.message}</p>
          )}
          <div className="ot-section__description">
            <p>Wir melden uns, sobald es losgeht!</p>
          </div>
        </div>
      </div>
    );
  }
}
const WrappedWaitingList = props => (
  <WaitingList {...props} location={useLocation()} />
);
export default WrappedWaitingList;
