import React, {Component} from 'react';

class DataProtection extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scrollt die Seite nach oben, sobald sie geladen ist
  }
  render() {
    return (
      <div>
        <div className="ot-section">
          <div className="ot-section__container">
            <h1>Datenschutz</h1>
            <div className="ot-data-protection">
              <h2 id="m4158">Präambel</h2>
              <p>
                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
                aufklären, welche Arten Ihrer personenbezogenen Daten
                (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen
                Zwecken und in welchem Umfang im Rahmen der Bereitstellung
                unserer Applikation verarbeiten.
              </p>
              <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
              <p>Stand: 4. Februar 2025</p>
              <h2>Inhaltsübersicht</h2>{' '}
              <ul class="index">
                <li>
                  <a class="index-link" href="#m4158">
                    Präambel
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m3">
                    Verantwortlicher
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#mOverview">
                    Übersicht der Verarbeitungen
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m2427">
                    Maßgebliche Rechtsgrundlagen
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m27">
                    Sicherheitsmaßnahmen
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m25">
                    Übermittlung von personenbezogenen Daten
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m24">
                    Internationale Datentransfers
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m12">
                    Allgemeine Informationen zur Datenspeicherung und Löschung
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m10">
                    Rechte der betroffenen Personen
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m225">
                    Bereitstellung des Onlineangebots und Webhosting
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m134">
                    Einsatz von Cookies
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m17">
                    Newsletter und elektronische Benachrichtigungen
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m638">
                    Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
                  </a>
                </li>
                <li>
                  <a class="index-link" href="#m15">
                    Änderung und Aktualisierung
                  </a>
                </li>
              </ul>
              <h2 id="m3">Verantwortlicher</h2>
              <p>Mirco Göpfert Lichtenstraße 7a 37327 Wingerode</p>
              <p>
                E-Mail-Adresse:{' '}
                <a href="mailto:mircogoepfert97@gmail.com">
                  mircogoepfert97@gmail.com
                </a>
              </p>
              <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
              <p>
                Die nachfolgende Übersicht fasst die Arten der verarbeiteten
                Daten und die Zwecke ihrer Verarbeitung zusammen und verweist
                auf die betroffenen Personen.
              </p>
              <h3>Arten der verarbeiteten Daten</h3>
              <ul>
                <li>Bestandsdaten.</li>
                <li>Kontaktdaten.</li>
                <li>Inhaltsdaten.</li>
                <li>Nutzungsdaten.</li>
                <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                <li>Protokolldaten.</li>
              </ul>
              <h3>Kategorien betroffener Personen</h3>
              <ul>
                <li>Kommunikationspartner.</li>
                <li>Nutzer.</li>
              </ul>
              <h3>Zwecke der Verarbeitung</h3>
              <ul>
                <li>Sicherheitsmaßnahmen.</li>
                <li>Direktmarketing.</li>
                <li>Marketing.</li>
                <li>
                  Bereitstellung unseres Onlineangebotes und
                  Nutzerfreundlichkeit.
                </li>
                <li>Informationstechnische Infrastruktur.</li>
                <li>Absatzförderung.</li>
              </ul>
              <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
              <p>
                <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
                Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
                DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten.
                Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der
                DSGVO nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn-
                oder Sitzland gelten können. Sollten ferner im Einzelfall
                speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen
                diese in der Datenschutzerklärung mit.
              </p>
              <ul>
                <li>
                  <strong>
                    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)
                  </strong>{' '}
                  - Die betroffene Person hat ihre Einwilligung in die
                  Verarbeitung der sie betreffenden personenbezogenen Daten für
                  einen spezifischen Zweck oder mehrere bestimmte Zwecke
                  gegeben.
                </li>
                <li>
                  <strong>
                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
                  </strong>{' '}
                  - die Verarbeitung ist zur Wahrung der berechtigten Interessen
                  des Verantwortlichen oder eines Dritten notwendig,
                  vorausgesetzt, dass die Interessen, Grundrechte und
                  Grundfreiheiten der betroffenen Person, die den Schutz
                  personenbezogener Daten verlangen, nicht überwiegen.
                </li>
              </ul>
              <p>
                <strong>
                  Nationale Datenschutzregelungen in Deutschland:{' '}
                </strong>
                Zusätzlich zu den Datenschutzregelungen der DSGVO gelten
                nationale Regelungen zum Datenschutz in Deutschland. Hierzu
                gehört insbesondere das Gesetz zum Schutz vor Missbrauch
                personenbezogener Daten bei der Datenverarbeitung
                (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                Spezialregelungen zum Recht auf Auskunft, zum Recht auf
                Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
                Kategorien personenbezogener Daten, zur Verarbeitung für andere
                Zwecke und zur Übermittlung sowie automatisierten
                Entscheidungsfindung im Einzelfall einschließlich Profiling.
                Ferner können Landesdatenschutzgesetze der einzelnen
                Bundesländer zur Anwendung gelangen.
              </p>
              <h2 id="m27">Sicherheitsmaßnahmen</h2>
              <p>
                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                Berücksichtigung des Stands der Technik, der
                Implementierungskosten und der Art, des Umfangs, der Umstände
                und der Zwecke der Verarbeitung sowie der unterschiedlichen
                Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
                Rechte und Freiheiten natürlicher Personen geeignete technische
                und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                Schutzniveau zu gewährleisten.
              </p>
              <p>
                Zu den Maßnahmen gehören insbesondere die Sicherung der
                Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
                Kontrolle des physischen und elektronischen Zugangs zu den Daten
                als auch des sie betreffenden Zugriffs, der Eingabe, der
                Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
                Des Weiteren haben wir Verfahren eingerichtet, die eine
                Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
                Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
                berücksichtigen wir den Schutz personenbezogener Daten bereits
                bei der Entwicklung bzw. Auswahl von Hardware, Software sowie
                Verfahren entsprechend dem Prinzip des Datenschutzes, durch
                Technikgestaltung und durch datenschutzfreundliche
                Voreinstellungen.
              </p>
              <p>
                Sicherung von Online-Verbindungen durch
                TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten der
                Nutzer, die über unsere Online-Dienste übertragen werden, vor
                unerlaubten Zugriffen zu schützen, setzen wir auf die
                TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL)
                und Transport Layer Security (TLS) sind die Eckpfeiler der
                sicheren Datenübertragung im Internet. Diese Technologien
                verschlüsseln die Informationen, die zwischen der Website oder
                App und dem Browser des Nutzers (oder zwischen zwei Servern)
                übertragen werden, wodurch die Daten vor unbefugtem Zugriff
                geschützt sind. TLS, als die weiterentwickelte und sicherere
                Version von SSL, gewährleistet, dass alle Datenübertragungen den
                höchsten Sicherheitsstandards entsprechen. Wenn eine Website
                durch ein SSL-/TLS-Zertifikat gesichert ist, wird dies durch die
                Anzeige von HTTPS in der URL signalisiert. Dies dient als ein
                Indikator für die Nutzer, dass ihre Daten sicher und
                verschlüsselt übertragen werden.
              </p>
              <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
              <p>
                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt
                es vor, dass diese an andere Stellen, Unternehmen, rechtlich
                selbstständige Organisationseinheiten oder Personen übermittelt
                beziehungsweise ihnen gegenüber offengelegt werden. Zu den
                Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte
                Dienstleister gehören oder Anbieter von Diensten und Inhalten,
                die in eine Website eingebunden sind. In solchen Fällen beachten
                wir die gesetzlichen Vorgaben und schließen insbesondere
                entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer
                Daten dienen, mit den Empfängern Ihrer Daten ab.
              </p>
              <h2 id="m24">Internationale Datentransfers</h2>
              <p>
                Datenverarbeitung in Drittländern: Sofern wir Daten in einem
                Drittland (d. h., außerhalb der Europäischen Union (EU), des
                Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
                Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
                oder der Offenlegung bzw. Übermittlung von Daten an andere
                Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur
                im Einklang mit den gesetzlichen Vorgaben. Sofern das
                Datenschutzniveau in dem Drittland mittels eines
                Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO),
                dient dieser als Grundlage des Datentransfers. Im Übrigen
                erfolgen Datentransfers nur dann, wenn das Datenschutzniveau
                anderweitig gesichert ist, insbesondere durch
                Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO),
                ausdrückliche Einwilligung oder im Fall vertraglicher oder
                gesetzlich erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO).
                Im Übrigen teilen wir Ihnen die Grundlagen der
                Drittlandübermittlung bei den einzelnen Anbietern aus dem
                Drittland mit, wobei die Angemessenheitsbeschlüsse als
                Grundlagen vorrangig gelten. Informationen zu Drittlandtransfers
                und vorliegenden Angemessenheitsbeschlüssen können dem
                Informationsangebot der EU-Kommission entnommen werden:{' '}
                <a
                  href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
                  target="_blank">
                  https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
                </a>{' '}
                Im Rahmen des sogenannten „Data Privacy Framework" (DPF) hat die
                EU-Kommission das Datenschutzniveau ebenfalls für bestimmte
                Unternehmen aus den USA im Rahmen der Angemessenheitsbeschlusses
                vom 10.07.2023 als sicher anerkannt. Die Liste der
                zertifizierten Unternehmen als auch weitere Informationen zu dem
                DPF können Sie der Website des Handelsministeriums der USA unter{' '}
                <a href="https://www.dataprivacyframework.gov/" target="_blank">
                  https://www.dataprivacyframework.gov/
                </a>{' '}
                (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
                Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter
                unter dem Data Privacy Framework zertifiziert sind.
              </p>
              <h2 id="m12">
                Allgemeine Informationen zur Datenspeicherung und Löschung
              </h2>
              <p>
                Wir löschen personenbezogene Daten, die wir verarbeiten, gemäß
                den gesetzlichen Bestimmungen, sobald die zugrundeliegenden
                Einwilligungen widerrufen werden oder keine weiteren rechtlichen
                Grundlagen für die Verarbeitung bestehen. Dies betrifft Fälle,
                in denen der ursprüngliche Verarbeitungszweck entfällt oder die
                Daten nicht mehr benötigt werden. Ausnahmen von dieser Regelung
                bestehen, wenn gesetzliche Pflichten oder besondere Interessen
                eine längere Aufbewahrung oder Archivierung der Daten erfordern.
              </p>
              <p>
                Insbesondere müssen Daten, die aus handels- oder
                steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
                Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz
                der Rechte anderer natürlicher oder juristischer Personen,
                entsprechend archiviert werden.
              </p>
              <p>
                Unsere Datenschutzhinweise enthalten zusätzliche Informationen
                zur Aufbewahrung und Löschung von Daten, die speziell für
                bestimmte Verarbeitungsprozesse gelten.
              </p>
              <p>
                Bei mehreren Angaben zur Aufbewahrungsdauer oder
                Löschungsfristen eines Datums, ist stets die längste Frist
                maßgeblich.
              </p>
              <p>
                Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum
                und beträgt sie mindestens ein Jahr, so startet sie automatisch
                am Ende des Kalenderjahres, in dem das fristauslösende Ereignis
                eingetreten ist. Im Fall laufender Vertragsverhältnisse, in
                deren Rahmen Daten gespeichert werden, ist das fristauslösende
                Ereignis der Zeitpunkt des Wirksamwerdens der Kündigung oder
                sonstige Beendigung des Rechtsverhältnisses.
              </p>
              <p>
                Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck,
                sondern aufgrund gesetzlicher Vorgaben oder anderer Gründe
                aufbewahrt werden, verarbeiten wir ausschließlich zu den
                Gründen, die ihre Aufbewahrung rechtfertigen.
              </p>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Aufbewahrung und Löschung von Daten: </strong>Die
                  folgenden allgemeinen Fristen gelten für die Aufbewahrung und
                  Archivierung nach deutschem Recht:
                  <ul>
                    {' '}
                    <li>
                      10 Jahre - Aufbewahrungsfrist für Bücher und
                      Aufzeichnungen, Jahresabschlüsse, Inventare, Lageberichte,
                      Eröffnungsbilanz sowie die zu ihrem Verständnis
                      erforderlichen Arbeitsanweisungen und sonstigen
                      Organisationsunterlagen (§ 147 Abs. 1 Nr. 1 i.V.m. Abs. 3
                      AO, § 14b Abs. 1 UStG, § 257 Abs. 1 Nr. 1 i.V.m. Abs. 4
                      HGB).
                    </li>
                    <li>
                      8 Jahre - Buchungsbelege, wie z. B. Rechnungen und
                      Kostenbelege (§ 147 Abs. 1 Nr. 4 und 4a i.V.m. Abs. 3 Satz
                      1 AO sowie § 257 Abs. 1 Nr. 4 i.V.m. Abs. 4 HGB).
                    </li>
                    <li>
                      6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels-
                      oder Geschäftsbriefe, Wiedergaben der abgesandten Handels-
                      oder Geschäftsbriefe, sonstige Unterlagen, soweit sie für
                      die Besteuerung von Bedeutung sind, z. B.
                      Stundenlohnzettel, Betriebsabrechnungsbögen,
                      Kalkulationsunterlagen, Preisauszeichnungen, aber auch
                      Lohnabrechnungsunterlagen, soweit sie nicht bereits
                      Buchungsbelege sind und Kassenstreifen (§ 147 Abs. 1 Nr.
                      2, 3, 5 i.V.m. Abs. 3 AO, § 257 Abs. 1 Nr. 2 u. 3 i.V.m.
                      Abs. 4 HGB).
                    </li>
                    <li>
                      3 Jahre - Daten, die erforderlich sind, um potenzielle
                      Gewährleistungs- und Schadensersatzansprüche oder ähnliche
                      vertragliche Ansprüche und Rechte zu berücksichtigen sowie
                      damit verbundene Anfragen zu bearbeiten, basierend auf
                      früheren Geschäftserfahrungen und üblichen
                      Branchenpraktiken, werden für die Dauer der regulären
                      gesetzlichen Verjährungsfrist von drei Jahren gespeichert
                      (§§ 195, 199 BGB).
                    </li>{' '}
                  </ul>
                </li>
              </ul>
              <h2 id="m10">Rechte der betroffenen Personen</h2>
              <p>
                Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
                Betroffene nach der DSGVO verschiedene Rechte zu, die sich
                insbesondere aus Art. 15 bis 21 DSGVO ergeben:
              </p>
              <ul>
                <li>
                  <strong>
                    Widerspruchsrecht: Sie haben das Recht, aus Gründen, die
                    sich aus Ihrer besonderen Situation ergeben, jederzeit gegen
                    die Verarbeitung der Sie betreffenden personenbezogenen
                    Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO
                    erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
                    diese Bestimmungen gestütztes Profiling. Werden die Sie
                    betreffenden personenbezogenen Daten verarbeitet, um
                    Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
                    Widerspruch gegen die Verarbeitung der Sie betreffenden
                    personenbezogenen Daten zum Zwecke derartiger Werbung
                    einzulegen; dies gilt auch für das Profiling, soweit es mit
                    solcher Direktwerbung in Verbindung steht.
                  </strong>
                </li>
                <li>
                  <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben
                  das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
                </li>
                <li>
                  <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
                  Bestätigung darüber zu verlangen, ob betreffende Daten
                  verarbeitet werden und auf Auskunft über diese Daten sowie auf
                  weitere Informationen und Kopie der Daten entsprechend den
                  gesetzlichen Vorgaben.
                </li>
                <li>
                  <strong>Recht auf Berichtigung:</strong> Sie haben
                  entsprechend den gesetzlichen Vorgaben das Recht, die
                  Vervollständigung der Sie betreffenden Daten oder die
                  Berichtigung der Sie betreffenden unrichtigen Daten zu
                  verlangen.
                </li>
                <li>
                  <strong>
                    Recht auf Löschung und Einschränkung der Verarbeitung:
                  </strong>{' '}
                  Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
                  verlangen, dass Sie betreffende Daten unverzüglich gelöscht
                  werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
                  eine Einschränkung der Verarbeitung der Daten zu verlangen.
                </li>
                <li>
                  <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
                  Recht, Sie betreffende Daten, die Sie uns bereitgestellt
                  haben, nach Maßgabe der gesetzlichen Vorgaben in einem
                  strukturierten, gängigen und maschinenlesbaren Format zu
                  erhalten oder deren Übermittlung an einen anderen
                  Verantwortlichen zu fordern.
                </li>
                <li>
                  <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
                  unbeschadet eines anderweitigen verwaltungsrechtlichen oder
                  gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
                  Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                  gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des
                  Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind,
                  dass die Verarbeitung der Sie betreffenden personenbezogenen
                  Daten gegen die Vorgaben der DSGVO verstößt.
                </li>
              </ul>
              <h2 id="m225">
                Bereitstellung des Onlineangebots und Webhosting
              </h2>
              <p>
                Wir verarbeiten die Daten der Nutzer, um ihnen unsere
                Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck
                verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist,
                um die Inhalte und Funktionen unserer Online-Dienste an den
                Browser oder das Endgerät der Nutzer zu übermitteln.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
                  Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität
                  und -frequenz, verwendete Gerätetypen und Betriebssysteme,
                  Interaktionen mit Inhalten und Funktionen); Meta-,
                  Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
                  Zeitangaben, Identifikationsnummern, beteiligte Personen).
                  Protokolldaten (z. B. Logfiles betreffend Logins oder den
                  Abruf von Daten oder Zugriffszeiten.).
                </li>
                <li>
                  <strong>Betroffene Personen:</strong> Nutzer (z. B.
                  Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li>
                  <strong>Zwecke der Verarbeitung:</strong> Bereitstellung
                  unseres Onlineangebotes und Nutzerfreundlichkeit;
                  Informationstechnische Infrastruktur (Betrieb und
                  Bereitstellung von Informationssystemen und technischen
                  Geräten (Computer, Server etc.)). Sicherheitsmaßnahmen.
                </li>
                <li>
                  <strong>Aufbewahrung und Löschung:</strong> Löschung
                  entsprechend Angaben im Abschnitt "Allgemeine Informationen
                  zur Datenspeicherung und Löschung".
                </li>
                <li class="">
                  <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                  (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                </li>
              </ul>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <ul class="m-elements">
                <li>
                  <strong>
                    Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{' '}
                  </strong>
                  Für die Bereitstellung unseres Onlineangebotes nutzen wir
                  Speicherplatz, Rechenkapazität und Software, die wir von einem
                  entsprechenden Serveranbieter (auch "Webhoster" genannt)
                  mieten oder anderweitig beziehen;{' '}
                  <span class="">
                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                    (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                  </span>
                </li>
                <li>
                  <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
                  Zugriff auf unser Onlineangebot wird in Form von sogenannten
                  "Server-Logfiles" protokolliert. Zu den Serverlogfiles können
                  die Adresse und der Name der abgerufenen Webseiten und
                  Dateien, Datum und Uhrzeit des Abrufs, übertragene
                  Datenmengen, Meldung über erfolgreichen Abruf, Browsertyp
                  nebst Version, das Betriebssystem des Nutzers, Referrer URL
                  (die zuvor besuchte Seite) und im Regelfall IP-Adressen und
                  der anfragende Provider gehören. Die Serverlogfiles können zum
                  einen zu Sicherheitszwecken eingesetzt werden, z. B. um eine
                  Überlastung der Server zu vermeiden (insbesondere im Fall von
                  missbräuchlichen Angriffen, sogenannten DDoS-Attacken), und
                  zum anderen, um die Auslastung der Server und ihre Stabilität
                  sicherzustellen;{' '}
                  <span class="">
                    <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                    (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).{' '}
                  </span>
                  <strong>Löschung von Daten:</strong> Logfile-Informationen
                  werden für die Dauer von maximal 30 Tagen gespeichert und
                  danach gelöscht oder anonymisiert. Daten, deren weitere
                  Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
                  endgültigen Klärung des jeweiligen Vorfalls von der Löschung
                  ausgenommen.
                </li>
              </ul>
              <h2 id="m134">Einsatz von Cookies</h2>
              <p>
                Unter dem Begriff „Cookies" werden Funktionen, die Informationen
                auf Endgeräten der Nutzer speichern und aus ihnen auslesen,
                verstanden. Cookies können ferner in Bezug auf unterschiedliche
                Anliegen Einsatz finden, etwa zu Zwecken der Funktionsfähigkeit,
                der Sicherheit und des Komforts von Onlineangeboten sowie der
                Erstellung von Analysen der Besucherströme. Wir verwenden
                Cookies gemäß den gesetzlichen Vorschriften. Dazu holen wir,
                wenn erforderlich, vorab die Zustimmung der Nutzer ein. Ist eine
                Zustimmung nicht notwendig, setzen wir auf unsere berechtigten
                Interessen. Dies gilt, wenn das Speichern und Auslesen von
                Informationen unerlässlich ist, um ausdrücklich angeforderte
                Inhalte und Funktionen bereitstellen zu können. Dazu zählen etwa
                die Speicherung von Einstellungen sowie die Sicherstellung der
                Funktionalität und Sicherheit unseres Onlineangebots. Die
                Einwilligung kann jederzeit widerrufen werden. Wir informieren
                klar über deren Umfang und welche Cookies genutzt werden.
              </p>
              <p>
                <strong>
                  Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:{' '}
                </strong>
                Ob wir personenbezogene Daten mithilfe von Cookies verarbeiten,
                hängt von einer Einwilligung ab. Liegt eine Einwilligung vor,
                dient sie als Rechtsgrundlage. Ohne Einwilligung stützen wir uns
                auf unsere berechtigten Interessen, die vorstehend in diesem
                Abschnitt und im Kontext der jeweiligen Dienste und Verfahren
                erläutert sind.
              </p>
              <p>
                <strong>Speicherdauer: </strong>Im Hinblick auf die
                Speicherdauer werden die folgenden Arten von Cookies
                unterschieden:
              </p>
              <ul>
                <li>
                  <strong>
                    Temporäre Cookies (auch: Session- oder Sitzungscookies):
                  </strong>{' '}
                  Temporäre Cookies werden spätestens gelöscht, nachdem ein
                  Nutzer ein Onlineangebot verlassen und sein Endgerät (z. B.
                  Browser oder mobile Applikation) geschlossen hat.
                </li>
                <li>
                  <strong>Permanente Cookies:</strong> Permanente Cookies
                  bleiben auch nach dem Schließen des Endgeräts gespeichert. So
                  können beispielsweise der Log-in-Status gespeichert und
                  bevorzugte Inhalte direkt angezeigt werden, wenn der Nutzer
                  eine Website erneut besucht. Ebenso können die mithilfe von
                  Cookies erhobenen Nutzerdaten zur Reichweitenmessung
                  Verwendung finden. Sofern wir Nutzern keine expliziten Angaben
                  zur Art und Speicherdauer von Cookies mitteilen (z. B. im
                  Rahmen der Einholung der Einwilligung), sollten sie davon
                  ausgehen, dass diese permanent sind und die Speicherdauer bis
                  zu zwei Jahre betragen kann.
                </li>
              </ul>
              <p>
                <strong>
                  Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out): 
                </strong>
                Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
                widerrufen und zudem einen Widerspruch gegen die Verarbeitung
                entsprechend den gesetzlichen Vorgaben, auch mittels der
                Privatsphäre-Einstellungen ihres Browsers, erklären.
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Verarbeitete Datenarten:</strong> Meta-,
                  Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
                  Zeitangaben, Identifikationsnummern, beteiligte Personen).
                </li>
                <li>
                  <strong>Betroffene Personen:</strong> Nutzer (z. B.
                  Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li class="">
                  <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
                  (Art. 6 Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs.
                  1 S. 1 lit. a) DSGVO).
                </li>
              </ul>
              <p>
                <strong>
                  Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                  Diensten:
                </strong>
              </p>
              <ul class="m-elements">
                <li>
                  <strong>
                    Verarbeitung von Cookie-Daten auf Grundlage einer
                    Einwilligung:{' '}
                  </strong>
                  Wir setzen eine Einwilligungs-Management-Lösung ein, bei der
                  die Einwilligung der Nutzer zur Verwendung von Cookies oder zu
                  den im Rahmen der Einwilligungs-Management-Lösung genannten
                  Verfahren und Anbietern eingeholt wird. Dieses Verfahren dient
                  der Einholung, Protokollierung, Verwaltung und dem Widerruf
                  von Einwilligungen, insbesondere bezogen auf den Einsatz von
                  Cookies und vergleichbaren Technologien, die zur Speicherung,
                  zum Auslesen und zur Verarbeitung von Informationen auf den
                  Endgeräten der Nutzer eingesetzt werden. Im Rahmen dieses
                  Verfahrens werden die Einwilligungen der Nutzer für die
                  Nutzung von Cookies und die damit verbundenen Verarbeitungen
                  von Informationen, einschließlich der im
                  Einwilligungs-Management-Verfahren genannten spezifischen
                  Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem
                  die Möglichkeit, ihre Einwilligungen zu verwalten und zu
                  widerrufen. Die Einwilligungserklärungen werden gespeichert,
                  um eine erneute Abfrage zu vermeiden und den Nachweis der
                  Einwilligung gemäß der gesetzlichen Anforderungen führen zu
                  können. Die Speicherung erfolgt serverseitig und/oder in einem
                  Cookie (sogenanntes Opt-In-Cookie) oder mittels vergleichbarer
                  Technologien, um die Einwilligung einem spezifischen Nutzer
                  oder dessen Gerät zuordnen zu können. Sofern keine
                  spezifischen Angaben zu den Anbietern von
                  Einwilligungs-Management-Diensten vorliegen, gelten folgende
                  allgemeine Hinweise: Die Dauer der Speicherung der
                  Einwilligung beträgt bis zu zwei Jahre. Dabei wird ein
                  pseudonymer Nutzer-Identifikator erstellt, der zusammen mit
                  dem Zeitpunkt der Einwilligung, den Angaben zum Umfang der
                  Einwilligung (z. B. betreffende Kategorien von Cookies
                  und/oder Diensteanbieter) sowie Informationen über den
                  Browser, das System und das verwendete Endgerät gespeichert
                  wird;{' '}
                  <span class="">
                    <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs.
                    1 S. 1 lit. a) DSGVO).
                  </span>
                </li>
              </ul>
              <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2>
              <p>
                Wir versenden Newsletter, E-Mails und weitere elektronische
                Benachrichtigungen (nachfolgend „Newsletter") ausschließlich mit
                der Einwilligung der Empfänger oder aufgrund einer gesetzlichen
                Grundlage. Sofern im Rahmen einer Anmeldung zum Newsletter
                dessen Inhalte genannt werden, sind diese Inhalte für die
                Einwilligung der Nutzer maßgeblich. Für die Anmeldung zu unserem
                Newsletter ist normalerweise die Angabe Ihrer E-Mail-Adresse
                ausreichend. Um Ihnen jedoch einen personalisierten Service
                bieten zu können, bitten wir gegebenenfalls um die Angabe Ihres
                Namens für eine persönliche Ansprache im Newsletter oder um
                weitere Informationen, falls diese für den Zweck des Newsletters
                notwendig sind.
              </p>
              <p>
                Löschung und Einschränkung der Verarbeitung: Wir können die
                ausgetragenen E-Mail-Adressen bis zu drei Jahren auf Grundlage
                unserer berechtigten Interessen speichern, bevor wir sie
                löschen, um eine ehemals gegebene Einwilligung nachweisen zu
                können. Die Verarbeitung dieser Daten wird auf den Zweck einer
                potenziellen Abwehr von Ansprüchen beschränkt. Ein individueller
                Löschungsantrag ist jederzeit möglich, sofern zugleich das
                ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall
                von Pflichten zur dauerhaften Beachtung von Widersprüchen
                behalten wir uns die Speicherung der E-Mail-Adresse alleine zu
                diesem Zweck in einer Sperrliste (sogenannte „Blocklist") vor.
              </p>
              <p>
                Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
                unserer berechtigten Interessen zum Zweck des Nachweises seines
                ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem
                Versand von E-Mails beauftragen, erfolgt dies auf Grundlage
                unserer berechtigten Interessen an einem effizienten und
                sicheren Versandsystem.
              </p>
              <strong>Inhalte:</strong>{' '}
              <p>Informationen zu uns, unseren Leistungen.</p>
              <ul class="m-elements">
                <li>
                  <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B.
                  der vollständige Name, Wohnadresse, Kontaktinformationen,
                  Kundennummer, etc.); Kontaktdaten (z. B. Post- und
                  E-Mail-Adressen oder Telefonnummern). Meta-, Kommunikations-
                  und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                  Identifikationsnummern, beteiligte Personen).
                </li>
                <li>
                  <strong>Betroffene Personen:</strong> Kommunikationspartner.
                </li>
                <li>
                  <strong>Zwecke der Verarbeitung:</strong> Direktmarketing
                  (z. B. per E-Mail oder postalisch).
                </li>
                <li class="">
                  <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                  S. 1 lit. a) DSGVO).
                </li>
                <li>
                  <strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können
                  den Empfang unseres Newsletters jederzeit kündigen, d. .h.
                  Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang
                  widersprechen. Einen Link zur Kündigung des Newsletters finden
                  Sie entweder am Ende eines jeden Newsletters oder können sonst
                  eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig
                  E-Mail, hierzu nutzen.
                </li>
              </ul>
              <h2 id="m638">
                Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
              </h2>
              <p>
                Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen
                Kommunikation, die über diverse Kanäle, wie z. B. E-Mail,
                Telefon, Post oder Fax, entsprechend den gesetzlichen Vorgaben
                erfolgen kann.
              </p>
              <p>
                Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit
                zu widerrufen oder der werblichen Kommunikation jederzeit zu
                widersprechen.
              </p>
              <p>
                Nach Widerruf oder Widerspruch speichern wir die zum Nachweis
                der bisherigen Berechtigung erforderlichen Daten zur
                Kontaktaufnahme oder Zusendung bis zu drei Jahre nach Ablauf des
                Jahres des Widerrufs oder Widerspruchs auf der Grundlage unserer
                berechtigten Interessen. Die Verarbeitung dieser Daten ist auf
                den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Auf
                der Grundlage des berechtigten Interesses, den Widerruf bzw.
                Widerspruch der Nutzer dauerhaft zu beachten, speichern wir
                ferner die zur Vermeidung einer erneuten Kontaktaufnahme
                erforderlichen Daten (z. B. je nach Kommunikationskanal die
                E-Mail-Adresse, Telefonnummer, Name).
              </p>
              <ul class="m-elements">
                <li>
                  <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B.
                  der vollständige Name, Wohnadresse, Kontaktinformationen,
                  Kundennummer, etc.); Kontaktdaten (z. B. Post- und
                  E-Mail-Adressen oder Telefonnummern). Inhaltsdaten (z. B.
                  textliche oder bildliche Nachrichten und Beiträge sowie die
                  sie betreffenden Informationen, wie z. B. Angaben zur
                  Autorenschaft oder Zeitpunkt der Erstellung).
                </li>
                <li>
                  <strong>Betroffene Personen:</strong> Kommunikationspartner.
                </li>
                <li>
                  <strong>Zwecke der Verarbeitung:</strong> Direktmarketing
                  (z. B. per E-Mail oder postalisch); Marketing.
                  Absatzförderung.
                </li>
                <li>
                  <strong>Aufbewahrung und Löschung:</strong> Löschung
                  entsprechend Angaben im Abschnitt "Allgemeine Informationen
                  zur Datenspeicherung und Löschung".
                </li>
                <li class="">
                  <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                  S. 1 lit. a) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S.
                  1 lit. f) DSGVO).
                </li>
              </ul>
              <h2 id="m15">Änderung und Aktualisierung</h2>
              <p>
                Wir bitten Sie, sich regelmäßig über den Inhalt unserer
                Datenschutzerklärung zu informieren. Wir passen die
                Datenschutzerklärung an, sobald die Änderungen der von uns
                durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
                informieren Sie, sobald durch die Änderungen eine
                Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder eine
                sonstige individuelle Benachrichtigung erforderlich wird.
              </p>
              <p>
                Sofern wir in dieser Datenschutzerklärung Adressen und
                Kontaktinformationen von Unternehmen und Organisationen angeben,
                bitten wir zu beachten, dass die Adressen sich über die Zeit
                ändern können und bitten die Angaben vor Kontaktaufnahme zu
                prüfen.
              </p>
              <p class="seal">
                <a
                  href="https://datenschutz-generator.de/"
                  title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                  target="_blank"
                  rel="noopener noreferrer nofollow">
                  Erstellt mit kostenlosem Datenschutz-Generator.de von Dr.
                  Thomas Schwenke
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataProtection;
