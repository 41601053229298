import React, {Component} from 'react';
import Section from '../section';

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scrollt die Seite nach oben, sobald sie geladen ist
  }
  render() {
    return (
      <div>
        <Section></Section>
      </div>
    );
  }
}

export default Home;
