import React, {Component} from 'react';
import '../../styles/03-components/section.scss';
import ContentBlock from '../content-block';

class SubscriptionPlans extends Component {
  componentDidMount() {
    window.scrollTo(0, 0); // Scrollt die Seite nach oben, sobald sie geladen ist
  }
  render() {
    return (
      <div className="ot-section">
        <div className="ot-section__container">
          <div className="ot-section__headline">
            <h1>Was wäre dir OffTogether wert?</h1>
          </div>
          <div className="ot-section__description">
            <p>Keine Zahlung – nur eine unverbindliche Einschätzung.</p>
          </div>
          <div className="ot-section__content">
            <ContentBlock
              title="Kostenlos (0€)"
              text="Reinschauen & loslegen!"
              items={[
                'Sportpartner finden',
                'Aktivitäten & Gruppen',
                'Kleine Wettbewerbe',
                'Begrenzte Teilnahmen',
              ]}
              linkPath="/waiting-list?plan=free"
              linkText="Weiter zur Warteliste"
            />
            <ContentBlock
              title="Supporter (5€/Monat)"
              text="Unterstütze & profitiere!"
              items={[
                'Alle Free-Features',
                'Exklusive Tests',
                'Öffentliche Communities',
                'Mehr Teilnahmen',
              ]}
              linkPath="/waiting-list?plan=supporter"
              linkText="Weiter zur Warteliste"
            />
            <ContentBlock
              title="Enthusiast (10€/Monat)"
              text="Maximaler Fun & Vorteile!"
              items={[
                'Alle Features',
                'Eigene Turniere',
                'Früherer Zugang',
                'Günstigere Preise bei Partnern',
              ]}
              linkPath="/waiting-list?plan=premium"
              linkText="Weiter zur Warteliste"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionPlans;
