import './App.css';
import Footer from './views/footer';
import Header from './views/header';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './views/pages/Home';
import AGB from './views/pages/AGB';
import SubscriptionPlans from './views/pages/SubscriptionPlans';
import WaitingList from './views/pages/WaitingList';
import DataProtection from './views/pages/DataProtection';

function App() {
  return (
    <Router>
      <div className="renderPage">
        <Header />
        <div className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/agbs" element={<AGB />} />
            <Route path="/plans" element={<SubscriptionPlans />} />
            <Route path="/waiting-list" element={<WaitingList />} />
            <Route path="/data-protection" element={<DataProtection />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
