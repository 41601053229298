import React, {Component} from 'react';
import '../styles/03-components/section.scss';
import ContentBlock from './content-block';

class Section extends Component {
  render() {
    return (
      <div className="ot-section">
        <div className="ot-section__container">
          <div className="ot-section__headline">
            <h1>Schluss mit Scrollen. Zeit für Offline-Momente!</h1>
          </div>
          <div className="ot-section__description">
            <p>
              Sport macht mehr Spaß mit den richtigen Leuten – egal ob Padel,
              Bowling oder Zumba!
            </p>
          </div>
          <div className="ot-section__content">
            <ContentBlock
              title="Sportpartner auf deinem Niveau finden"
              text="Finde Gleichgesinnte in deiner Nähe und verabredet euch für gemeinsame Aktivitäten."
              iconSrc="/icons/heart-beat.png"
              linkPath="/plans"
              linkText="Mehr erfahren"
            />
            <ContentBlock
              title="Gruppen gründen"
              text="Erstelle Gruppen, um dich regelmäßig mit anderen zu vernetzen und gemeinsame Aktivitäten zu planen. Perfekt für Teams, Vereine oder spontane Treffen."
              iconSrc="/icons/group.png" // Optional für ein anderes Icon
              linkPath="/plans"
              linkText="Mehr erfahren"
            />
            <ContentBlock
              title="Messe dich mit anderen"
              text="Tritt in spannenden Wettkämpfen an, verfolge deine Ergebnisse und wachse an der Herausforderung – Spaß und Motivation garantiert."
              iconSrc="/icons/award.png"
              linkPath="/plans"
              linkText="Mehr erfahren"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Section;
