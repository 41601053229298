import React, {Component} from 'react';
import '../styles/03-components/content-block.scss';
import '../styles/02-common/buttons.scss';
import {Link} from 'react-router-dom';

class ContentBlock extends Component {
  render() {
    const {title, text, iconSrc, items, linkPath, linkText} = this.props;
    return (
      <div className="ot-content-block">
        <div className="ot-content-block__container">
          {iconSrc && (
            <div className="ot-content-image">
              <span className="icon">
                <img src={iconSrc} alt={title} width="42" height="42" />
              </span>
            </div>
          )}
          <div className="ot-content-title">
            <h2>{title}</h2>
          </div>
          <div className="ot-content-text">
            <p>{text}</p>
          </div>
          {items && items.length > 0 && (
            <ul className="ot-content-list">
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
          <Link to={linkPath}>
            <button className="ot-primary-btn">
              <span className="ot-primary-btn__text">{linkText}</span>
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default ContentBlock;
